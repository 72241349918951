/* eslint-disable global-require */
declare const fbq: any

exports.onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`)
  }
}

exports.onRouteUpdate = () => {
  // Don't track while developing.
  if (
    process.env.NODE_ENV === `production` &&
    typeof fbq === `function`
  ) {
    // eslint-disable-next-line no-undef
    fbq('track', 'PageView')
  }
}
